import React, { useState } from 'react'
import Card from './comparison-card.styles'
import Typography from '@eig-builder/core-ui/Typography'
import PropTypes from 'prop-types'

const ComparisonCard = ({ header, features }) => {
  const { banner, plan, description } = header
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handlePopperOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card plan={plan}>
      {banner && <Card.topBanner>Most Popular</Card.topBanner>}
      <Card.headerContainer>
        <Card.title>{plan}</Card.title>
        <Card.subtitle>{description}</Card.subtitle>
        <Card.listContainer>
          {features.map(
            ({ label, isLineThrough, isTitle, isBoldUnderline }) => (
              <>
                {label === 'Priority Support' ? (
                  <Card.featureContainer>
                    <Card.featurePrioritySupport isLineThrough={isLineThrough}>
                      {label}
                    </Card.featurePrioritySupport>
                    {!isLineThrough && (
                      <Typography onMouseEnter={handlePopperOpen}>
                        <Card.icon onMouseLeave={handlePopperClose} />
                      </Typography>
                    )}
                  </Card.featureContainer>
                ) : (
                  <Card.feature
                    key={label}
                    isLineThrough={isLineThrough}
                    isTitle={isTitle}
                    isBoldUnderline={isBoldUnderline}
                  >
                    {label}
                  </Card.feature>
                )}
              </>
            ),
          )}
          <Card.popper
            open={open}
            anchorEl={anchorEl}
            style={{ pointerEvents: 'none' }}
            placement='top'
            disableRestoreFocus
            disableScrollLock
          >
            <Card.popperContainer>
              <Card.popperTitle>Priority Support</Card.popperTitle>
              <Card.popperParagraph>
                Jump right to the front of the support line to get the help you
                need fast
              </Card.popperParagraph>
            </Card.popperContainer>
          </Card.popper>
        </Card.listContainer>
      </Card.headerContainer>
    </Card>
  )
}

ComparisonCard.propTypes = {
  isMonthly: PropTypes.bool.isRequired,
}

export default ComparisonCard
