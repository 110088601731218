import React from 'react'
import Layout from '../components/layout'
import CTA from '../components/cta'
import SEO from '../components/seo'
import PricingSection from '../components/pricing-section'
import FeatureComparison from '../components/feature/feature-comparison'

const Pricing = () => {
  return (
    <Layout>
      <SEO title='Pricing' pageType='Pricing' flowType='none' />
      <PricingSection noTitle />
      <FeatureComparison />
      <CTA />
    </Layout>
  )
}

export default Pricing
