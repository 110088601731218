import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'
import InfoIcon from '@material-ui/icons/Info'
import Popper from '@material-ui/core/Popper'

const Card = styled.div`
  max-width: 100%;
  text-align: center;
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.shadow};
  margin-top: ${theme.space[6]}px;
  margin-right: ${theme.space[2]}px;

  ${media('md')} {
    margin-top: 0;
    width: 210px;
    margin-right: 0;
  }

  ${media('900')} {
    width: 230px;
  }

  ${media('lg')} {
    width: 252px;
  }

  ${media('xl')} {
    width: 272px;
  }
`

Card.topBanner = styled.div`
  position: relative;
  text-transform: uppercase;
  background-color: ${theme.colors.green};
  width: 100%;
  display: block;
  color: ${theme.colors.white};
  font-size: ${theme.font['sm']};
  padding: ${theme.space[2]}px 0;
  border-radius: 2px 2px 0 0;
  border-left: 1px solid ${theme.colors.shadow};
  border-right: 1px solid ${theme.colors.shadow};
`

Card.headerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`

Card.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[5]}px 0;
`

Card.subtitle = styled.span`
  color: ${theme.colors.font.gray};
  font-size: ${theme.font['sm']};
  padding-bottom: ${theme.space[8]}px;
  border-bottom: 2px solid ${theme.colors.shadow};
  margin-bottom: ${theme.space[6]}px;
  margin: 0 ${theme.space[4]}px;
  height: 72px;
`

Card.listContainer = styled.div`
  padding: ${theme.space[2]}px 0;
  margin: ${theme.space[4]}px ${theme.space[5]}px ${theme.space[10]}px
    ${theme.space[5]}px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${theme.colors.shadow};
`

Card.featureContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`

Card.icon = styled(InfoIcon)`
  && {
    position: absolute;
    font-size: ${theme.font['md']};
    color: #686868;
    margin-top: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`

const handleBorderType = (props) => {
  if (props.isTitle) {
    return 'none'
  } else if (props.isBoldUnderline) {
    return `2px solid ${theme.colors.shadow}`
  } else {
    return `1px dotted ${theme.colors.shadow}`
  }
}

Card.feature = styled.span`
  color: ${(props) =>
    props.isLineThrough ? theme.colors.shadow : theme.colors.font.darkGray};
  text-decoration: ${(props) =>
    props.isLineThrough ? 'line-through' : 'none'};
  font-size: ${(props) =>
    props.isTitle ? theme.font['lg'] : theme.font['sm']};
  border-bottom: ${(props) => handleBorderType(props)};
  text-transform: ${(props) => (props.isTitle ? 'uppercase' : 'none')};
  padding: ${theme.space[4]}px 0;
  margin: 0;
  white-space: nowrap;
  text-align: center;

  ${media('lg')} {
    margin: 0 ${theme.space[3]}px;
    padding: ${(props) =>
      props.isTitle
        ? theme.space[8] +
          'px ' +
          theme.space[3] +
          'px ' +
          theme.space[3] +
          'px'
        : theme.space[4] + 'px'};
  }
`

Card.featurePrioritySupport = styled.span`
  color: ${(props) =>
    props.isLineThrough ? theme.colors.shadow : theme.colors.font.darkGray};
  text-decoration: ${(props) =>
    props.isLineThrough ? 'line-through' : 'none'};
  font-size: ${theme.font['sm']};
  padding: ${theme.space[4]}px ${theme.space[1]}px;
  margin: 0;
  white-space: nowrap;
`

Card.popper = styled(Popper)`
  pointer-events: none;
  background-color: ${theme.colors.white};
  height: 120px;
  width: 250px;
  padding: ${theme.space[3]}px;
  overflow: hidden;
  border-radius: 2%;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: ${theme.space[3]}px;
  margin-right: 112px;
`

Card.popperContainer = styled.div`
  display: flex;
  flex-direction: column;
`

Card.popperTitle = styled.span`
  font-size: ${theme.font['sm']};
  padding-bottom: ${theme.space[3]}px;
`

Card.popperParagraph = styled.span`
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.gray};
`

export default Card
