import React, { useState } from 'react'
import {
  PricingCardPro,
  PricingCardStarter,
  PricingCardFree,
  PricingCardEcommerce,
} from '../price-card'
import CardLayout from '../card-layout/card-layout.styles'
import Radio from '@eig-builder/core-ui/Radio'
import { getCurrencySymbol } from 'helpers/currency-helper'
import Typography from '@eig-builder/core-ui/Typography'

const Pricing = ({ noTitle }) => {
  const [isMonthly, setIsMonthly] = useState('yearly')

  const handleChange = (e) => {
    setIsMonthly(e.target.value)
  }

  return (
    <CardLayout noTitle={noTitle}>
      <CardLayout.contentContainer>
        {!noTitle && (
          <>
            <CardLayout.title>Does your site need more?</CardLayout.title>
            <CardLayout.subtitle>
              Whatever tools you need, we have the plan for you
            </CardLayout.subtitle>
          </>
        )}
        <CardLayout.radioGroup value={isMonthly} onChange={handleChange}>
          <CardLayout.formControlLabel
            isSelected={isMonthly == 'monthly'}
            value={'monthly'}
            control={<Radio />}
            label='Monthly'
          />
          <CardLayout.formControlLabel
            isSelected={isMonthly == 'yearly'}
            value={'yearly'}
            control={<Radio />}
            label='Yearly'
          />
        </CardLayout.radioGroup>
      </CardLayout.contentContainer>
      <CardLayout.cardContainer>
        <PricingCardFree />
        <PricingCardStarter isMonthly={isMonthly == 'monthly'} />
        <PricingCardPro isMonthly={isMonthly == 'monthly'} />
        <PricingCardEcommerce isMonthly={isMonthly == 'monthly'} />
      </CardLayout.cardContainer>
      {!noTitle && (
        <>
          <CardLayout.contentContainer>
            <CardLayout.disclaimer>
              <Typography gutterBottom>
                *For customers in regions in which VAT (Value Added Tax) or GST
                (Goods and Services Tax) is applicable, taxes are not included
                in our advertised price and will be charged separately and
                itemized on invoices and billing information.{' '}
                <a
                  href={process.env.GATSBY_EU_VAT}
                  targe='_blank'
                  rel='noopener norefferer'
                >
                  Learn more
                </a>
              </Typography>
              <Typography>
                **Qualified plans include a free domain registration for one
                year. If you wish to cancel, there is a non-refundable{' '}
                {getCurrencySymbol().domainPrice} domain fee.
              </Typography>
            </CardLayout.disclaimer>
          </CardLayout.contentContainer>
        </>
      )}
    </CardLayout>
  )
}

export default Pricing
