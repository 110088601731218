import React from 'react'
import ComparisonCard from '../../comparison-card'
import CardLayout from '../../card-layout/card-layout.styles'
import {
  COMPARISON_HEADER_FREE,
  COMPARISON_HEADER_STARTER,
  COMPARISON_HEADER_PRO,
  COMPARISON_HEADER_ECOMMERCE,
  COMPARISON_LIST_FREE,
  COMPARISON_LIST_STARTER,
  COMPARISON_LIST_PRO,
  COMPARISON_LIST_ECOMMERCE,
} from '../../comparison-card/comparison-card.constants'
import { getCurrencySymbol } from 'helpers/currency-helper'
import Typography from '@eig-builder/core-ui/Typography'

const FeatureComparison = ({ noTitle }) => {
  return (
    <CardLayout featureComparison noTitle={noTitle}>
      <CardLayout.contentContainer>
        <CardLayout.title>Compare our full feature list</CardLayout.title>
        <CardLayout.subtitle>
          Here are all the amazing features that can help you create your dream
          website
        </CardLayout.subtitle>
      </CardLayout.contentContainer>
      <CardLayout.cardContainer>
        <ComparisonCard
          header={COMPARISON_HEADER_FREE}
          features={COMPARISON_LIST_FREE}
        />
        <ComparisonCard
          header={COMPARISON_HEADER_STARTER}
          features={COMPARISON_LIST_STARTER}
        />
        <ComparisonCard
          header={COMPARISON_HEADER_PRO}
          features={COMPARISON_LIST_PRO}
        />
        <ComparisonCard
          header={COMPARISON_HEADER_ECOMMERCE}
          features={COMPARISON_LIST_ECOMMERCE}
        />
      </CardLayout.cardContainer>
      <CardLayout.contentContainer>
        <CardLayout.disclaimer>
          <Typography gutterBottom>
            *For customers in regions in which VAT (Value Added Tax) or GST
            (Goods and Services Tax) is applicable, taxes are not included in
            our advertised price and will be charged separately and itemized on
            invoices and billing information.{' '}
            <a
              href={process.env.GATSBY_EU_VAT}
              targe='_blank'
              rel='noopener norefferer'
            >
              Learn more
            </a>
          </Typography>
          <Typography>
            **Qualified plans include a free domain registration for one year.
            If you wish to cancel, there is a non-refundable{' '}
            {getCurrencySymbol().domainPrice} domain fee.
          </Typography>
        </CardLayout.disclaimer>
      </CardLayout.contentContainer>
    </CardLayout>
  )
}

export default FeatureComparison
