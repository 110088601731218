export const COMPARISON_HEADER_FREE = {
  banner: false, plan: 'Free', description: ''
}
export const COMPARISON_HEADER_STARTER = {
  banner: false, plan: 'Starter', description: 'Perfect for your every website'
}
export const COMPARISON_HEADER_PRO = {
  banner: true, plan: 'Pro', description: 'Get all those extra features you need'
}
export const COMPARISON_HEADER_ECOMMERCE = {
  banner: false, plan: 'eCommerce', description: 'Ideal for selling your products online'
}

export const COMPARISON_LIST_FREE = [
  {label: "FREE Hosting", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Connect Your Domain", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Remove Ads", isLineThrough: true, isTitle: false, isBoldUnderline: true},
  {label: "Essentials", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Mobile Site", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Image Library", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Favicon", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Ecommerce", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Site Traffic", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Social Media Links", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Google Analytics", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Ad Credits", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Company Directory", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Support", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Support Center", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Priority Support", isLineThrough: true, isTitle: false, isBoldUnderline: true}
]
export const COMPARISON_LIST_STARTER = [
  {label: "FREE Hosting", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Connect Your Domain", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Remove Ads", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Essentials", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Mobile Site", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Image Library", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Favicon", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Ecommerce", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Site Traffic", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Social Media Links", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Google Analytics", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Ad Credits", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Company Directory", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Support", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Support Center", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Priority Support", isLineThrough: true, isTitle: false, isBoldUnderline: true}
]

export const COMPARISON_LIST_PRO = [
  {label: "FREE Hosting", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Connect Your Domain", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Remove Ads", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Essentials", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Mobile Site", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Image Library", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Favicon", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Ecommerce", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Site Traffic", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Social Media Links", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Google Analytics", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Ad Credits", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Company Directory", isLineThrough: true, isTitle: false, isBoldUnderline: false},
  {label: "Support", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Support Center", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Priority Support", isLineThrough: false, isTitle: false, isBoldUnderline: true}
]
export const COMPARISON_LIST_ECOMMERCE = [
  {label: "FREE Hosting", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Connect Your Domain", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Remove Ads", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Essentials", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Mobile Site", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Image Library", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Favicon", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Ecommerce", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Site Traffic", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Social Media Links", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Google Analytics", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Ad Credits", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Company Directory", isLineThrough: false, isTitle: false, isBoldUnderline: false},
  {label: "Support", isLineThrough: false, isTitle: false, isBoldUnderline: true},
  {label: "Support Center", isLineThrough: false, isTitle: true, isBoldUnderline: false},
  {label: "Priority Support", isLineThrough: false, isTitle: false, isBoldUnderline: true}
]



